<template>
  <div>
    <b-modal
      id="modalRule"
      ref="ModalRuleRef"
      :modal-class="myclass"
      hide-header
      hide-footer
    >
      <div class="modal-header">
        <div class="circle"><i class="icon icon-legal"></i></div>
      </div>

      <div class="modal-main">
        <section class="rule-cont" v-for="item in $t('store.dialogRule_list')">
          <div class="title">{{ item.title }}</div>
          <p>{{ item.cont1 }}</p>
          <p>{{ item.cont2 }}</p>
          <p>{{ item.cont3 }}</p>
          <p>{{ item.cont4 }}</p>
          <p>{{ item.cont5 }}</p>
          <p>{{ item.cont6 }}</p>
          <p>{{ item.cont7 }}</p>
        </section>
        <b-button variant="submit" block @click="hideModal">{{
          $t("store.btn.close")
        }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      myclass: ["dialog-rule"],
    };
  },
  methods: {
    audioClick() {
      if(this.DEF_webStyle === 'jdf'|| this.DEF_webStyle === 'fl8n' || this.DEF_webStyle === 'fl88'|| this.DEF_webStyle === 'kb88' || this.DEF_webStyle === 'jh8'){
        if(this.IsAudio) {
            const audio = document.querySelector("#beep");
            const context = new AudioContext();
            function isIOS() {
              return /iPhone|iPad|iPod/i.test(navigator.userAgent);
            };
            if (context.state === 'suspended') {
              context.resume().then(() => {});
            };
            if (isIOS()) {
              setTimeout(function() {
                audio.muted = false;
                audio.play();
              }, 200);
            } else {
              audio.muted = false;
              audio.play();
            };
          }
        // const audio = document.querySelector("#beep");
        // const context = new AudioContext();
        // function isIOS() {
        //   return /iPhone|iPad|iPod/i.test(navigator.userAgent);
        // };
        // if (context.state === 'suspended') {
        //   context.resume().then(() => {});
        // };
        // if (isIOS()) {
        //   setTimeout(function() {
        //     audio.muted = false;
        //     audio.play();
        //   }, 200);
        // } else {
        //   audio.muted = false;
        //   audio.play();
        // };
      };
    },
    ruleModal() {
      this.$root.$emit("bv::toggle::modal", "modalRule", "#btnRule");
    },
    hideModal() {
      this.audioClick();
      this.$refs.ModalRuleRef.hide();
    },
  },
  computed: {
    DEF_webStyle() {
      return process.env.DEF_webStyle || 'default';
    },
    ...mapState(['IsAudio']),
  },
};
</script>
